import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';

export enum LoginFlow {
  SIGNUP = 'signup',
  LOGIN = 'login',
}

const Login = React.lazy(() => import('./Login'));

const Auth = () => (
  <React.Suspense>
    <Switch>
      <Route
        path={`/${LoginFlow.SIGNUP}`}
        exact
        render={(props) => <Login flow={LoginFlow.SIGNUP} {...props} />}
      />
      <Route component={(props) => <Home />} />
    </Switch>
  </React.Suspense>
);

export default Auth;
